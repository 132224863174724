import React, { PureComponent } from 'react';

class Slider extends PureComponent {
  constructor(props) {
    super(props);
    this.videoEle = React.createRef();
    this.state = {
      sliderIndex: 0,
      isPlayed: false,
      vidPlay: [],
      vidPause: [],
    };
  }
  componentDidUpdate() {
    if (this.props.items.length === 1) {
      this.setPlayPauseVideo(this.props.top);
    }
  }
  setPlayPauseVideo(top) {
    try {
      const videoUrl = this.props.items[this.state.sliderIndex].src;
      if (this.state.vidPlay.length > 0) {
        const videoIndex = this.state.vidPlay.findIndex(
          (url) => url === videoUrl,
        );
        if (videoIndex !== -1) {
          this.setState(
            {
              isPlayed: true,
            },
            () => {
              this.playVideo(top);
            },
          );
        }
      }
      if (this.state.vidPause.length > 0) {
        const videoIndex = this.state.vidPause.findIndex(
          (url) => url === videoUrl,
        );
        if (videoIndex !== -1) {
          this.setState(
            {
              isPlayed: false,
            },
            () => {
              this.pauseVideo(top);
            },
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  componentDidMount() {
    try {
      this.setState(
        {
          sliderIndex: this.props.index,
        },
        () => {
          if (this.props.items.length === 1) {
            this.setPlayPauseVideo(this.props.top);
          }
        },
      );
    } catch (error) {
      console.log(error);
    }
  }
  async showNext() {
    try {
      this.setState(
        (prevState) => {
          return {
            sliderIndex: prevState.sliderIndex + 1,
          };
        },
        () => {
          console.log('Index :', this.state.sliderIndex);
          console.log(this.props.items[this.state.sliderIndex]);
        },
      );
    } catch (error) {
      console.log(error);
    }
  }
  showPrevious() {
    try {
      let index = this.state.sliderIndex - 1;
      this.setState({
        sliderIndex: index,
      });
    } catch (error) {
      console.log(error);
    }
  }
  vidPause = () => {
    const videoElement = this.videoEle.current;
    if (videoElement) {
      const src = videoElement.querySelector('source')?.src;
      if (src) {
        this.setState((prevState) => {
          const updatedVidPause = [...prevState.vidPause, src];
          const updatedVidPlay = prevState.vidPlay.filter((url) => url !== src);
          return { vidPause: updatedVidPause, vidPlay: updatedVidPlay };
        });
      }
    }
  };

  vidPlay = () => {
    const videoElement = this.videoEle.current;
    if (videoElement) {
      const src = videoElement.querySelector('source')?.src;
      if (src) {
        this.setState((prevState) => {
          const updatedVidPlay = [...prevState.vidPlay, src];
          const updatedVidPause = prevState.vidPause.filter(
            (url) => url !== src,
          );
          return { vidPlay: updatedVidPlay, vidPause: updatedVidPause };
        });
      }
    }
  };

  async playVideo() {
    try {
      if (this.videoEle.current) {
        await this.videoEle.current.play();
      }
    } catch (err) {
      console.log(err);
    }
  }

  async pauseVideo() {
    try {
      if (this.videoEle.current) {
        await this.videoEle.current.pause();
      }
    } catch (err) {
      console.log(err);
    }
  }
  showSlide(index) {
    try {
      let obj = this.props.items[index];
      return obj.type === 'image' ? <img src={obj.src} /> : '';
    } catch (error) {
      console.log(error);
    }
  }
  closeSlider() {
    try {
      this.props.onClose();
    } catch (error) {
      console.log(error);
    }
  }
  enlarge(src) {
    var win = window.open(src, '_blank');
    win.focus();
  }
  topBtns(src) {
    try {
      return (
        <>
          {/* <button className="closebtn" onClick={() => this.closeSlider()}>
            <img src="/img/top-left-arrow.svg" />
          </button>
          <button className="enlargeBtn" onClick={() => this.enlarge(src)}>
            <img src="/img/top-right-arrow.svg" />
          </button> */}
        </>
      );
    } catch (error) {
      console.log(error);
    }
  }

  prevBtn() {
    try {
      return this.state.sliderIndex > 0 ? (
        <button className="prev" onClick={() => this.showPrevious()}>
          <span>
            {' '}
            <img
              src="/img/arrow-left.svg"
              width="35"
              height="35"
              className="bottomleft"
            />
          </span>
        </button>
      ) : (
        ''
      );
    } catch (error) {
      console.log(error);
    }
  }

  nextBtn() {
    try {
      return this.state.sliderIndex + 1 < this.props.items.length ? (
        <button className="next" onClick={() => this.showNext()}>
          <span>
            {' '}
            <img
              src="/img/arrow-right.svg"
              width="35"
              height="35"
              className="bottomright"
            />
          </span>
        </button>
      ) : (
        ''
      );
    } catch (error) {
      console.log(error);
    }
  }
  render() {
    return (
      <div className="slider">
        <div className="stage">
          {this.props.items[this.state.sliderIndex].type === 'image' ? (
            <>
              <img src={this.props.items[this.state.sliderIndex].src} />
            </>
          ) : (
            <>
              <video
                onPause={this.vidPause}
                onPlay={this.vidPlay}
                muted="muted"
                width="320"
                height="240"
                controls
                ref={this.videoEle}
                key={this.props.items[this.state.sliderIndex].id}
              >
                <source
                  src={this.props.items[this.state.sliderIndex].videoUrl}
                  type="video/mp4"
                />
              </video>
            </>
          )}
        </div>

        {this.prevBtn()}
        {this.nextBtn()}
        {this.topBtns(this.props.items[this.state.sliderIndex].src)}
      </div>
    );
  }
}

export default React.memo(Slider);
