import React, { PureComponent } from 'react';
import RoleList from './RoleList';
import PostCarousel from './PostCarousel';
import SchedulePost from './SchedulePost';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { API } from './api/API';
import Poll from './poll/Poll';
import EditPoll from './poll/EditPoll';
import AlertBox from './ui/AlertBox';
import AlterPostFooter from './post/addedit/AlterPostFooter';

/* **************************************************************************** */
/*                            CREATE POST                                       */
/* **************************************************************************** */
/*
    PROPS : onCrawlPostList()
*/

class CreatePost extends PureComponent {
  constructor(props) {
    try {
      super(props);
      this.state = {
        id: null,
        logo: '/img/logo.png',
        counter: 0,
        showTitle: false,
        showContSection: false,
        showCarousel: false,
        showSchedulePanel: false,
        showImage: false,
        showVideo: false,

        title: '',
        publishNow: null,
        description: { value: 'onStatus' },
        creatorId: null,
        editorConfiguration: {
          placeholder: 'Great, continue typing here to elaborate.',
          removePlugins: ['Table', 'MediaEmbed'],
        },
        sharewithoptions: [
          {
            id: 0,
            title: 'Everyone in your Organisation',
            value: 'everyone',
            img: '/img/org.svg',
            tiny: null,
            classname: 'ev',
          },
          {
            id: 1,
            title: 'Only Admin',
            value: 'onlyadmin',
            img: null,
            tiny: 'AD',
            classname: 'ad',
          },
          {
            id: 2,
            title: 'Only Learners',
            value: 'onlylearners',
            img: null,
            tiny: 'LR',
            classname: 'lr',
          },
          {
            id: 3,
            title: 'Only Yourself',
            value: 'private',
            to: 0,
            img: '/img/private.svg',
            tiny: null,
            classname: 'pv',
          },
        ],
        sharewith: null,
        selectedFiles: [],
        deletedItems: [],
        mode: 'create',
        newsFeedID: null,
        showPollForm: false,
        showCreatePollBtn: false,
        showAlert: false,
        alertMsg: '',
        publishLaterDate: null,
        publishAt: null,
        poll: {
          options: [],
          type: 'multiple',
          startsAt: null,
          closesAt: null,
          pollQuestion: '',
        },
        isSchedule: false,
        dayList: [
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
        ],
        userInfo: null,
      };

      this.setPostTitle = this.setPostTitle.bind(this);
      //this.addToCarousel = this.addToCarousel.bind(this);
      this.createSchedulePost = this.createSchedulePost.bind(this);
      this.imageFile = React.createRef();
      this.videoFile = React.createRef();
      this.attachmentFile = React.createRef();
    } catch (error) {
      console.log(error);
    }
  }

  removeSchedule() {
    try {
      this.setState({
        publishNow: 1,
        showSchedulePanel: false,
        publishLaterDate: null,
      });
    } catch (error) {
      console.log(error);
    }
  }
  getContent() {
    var content = '';
    if (this.state.description && this.state.description.value) {
      content = this.state.description.value;
    }
    return content;
  }

  setPostTitle(event) {
    try {
      this.setState({
        title: event.target.value,
      });
    } catch (error) {
      console.log(error);
    }
  }

  showTitle() {
    try {
      this.setState({
        showTitle: true,
      });
    } catch (error) {
      console.log(error);
    }
  }
  showContent() {
    try {
      this.setState({
        showContSection: true,
        showCreatePollBtn: true,
      });
    } catch (error) {
      console.log(error);
    }
  }
  showCarousel() {
    try {
      this.setState({
        showCarousel: true,
      });
    } catch (error) {
      console.log(error);
    }
  }
  showSchedulePanel() {
    try {
      this.setState({
        showSchedulePanel: true,
      });
    } catch (error) {
      console.log(error);
    }
  }
  hideSchedulePanel = (value) => {
    try {
      this.setState({
        showSchedulePanel: value,
      });
    } catch (error) {
      console.log(error);
    }
  };
  handleChange = async (value) => {
    try {
      await this.setState({ showSchedulePanel: false });
    } catch (error) {
      console.log(error);
    }
  };
  closeCreatePost = async () => {
    try {
      if (this.state.mode === 'edit') {
        this.props.onChangeMode(false);
      } else if (this.state.mode === 'create') {
        await this.setState({
          showTitle: false,
          showContSection: false,
          showCarousel: false,
          showSchedulePanel: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  setFileType(file) {
    try {
      return file.type.search('image') === 0
        ? 'image'
        : file.type.search('video') === 0
        ? 'video'
        : file.type.search('pdf') >= 0
        ? 'attachment'
        : '';
    } catch (error) {
      console.log(error);
    }
  }

  toggleImage = (event) => {
    this.setState({
      showVideo: true,
      // showImage: false,
    });
  };

  toggleVideo = (event) => {
    this.setState({
      showImage: true,
      // showVideo: false,
    });
  };

  toggleImageVideo = (event) => {
    this.setState({
      showVideo: false,
      showImage: false,
    });
  };

  addToCarousel(event) {
    try {
      let selectedFiles = [...this.state.selectedFiles];
      let counter = this.state.counter;
      let fileTypeError = true;
      if (event.current.files.length > 0) {
        let i = 0;
        while (i < event.current.files.length) {
          counter++;

          // Validation if file does not have extension
          if (event.current.files[i].type.length === 0) {
            fileTypeError = false;
          }

          // If image is choosen then need to accept only image file only
          let imageRegxPatt = /\.(jpe?g|png|gif|bmp|svg)$/i;
          if (
            event.current.dataset.type === 'image' &&
            imageRegxPatt.test(event.current.value) === false
          ) {
            fileTypeError = false;
          }

          // If video is choosen then need to accept only image file only
          let videoRegxPatt = /\.(mp4|mp3|mpeg|avi|wmv|mov)$/i;
          if (
            event.current.dataset.type === 'video' &&
            videoRegxPatt.test(event.current.value) === false
          ) {
            fileTypeError = false;
          }

          // If attachment is choosen then need to accept only image file only
          let attachmentPatt = /\.(pdf|doc?x|ppt?x|xsl?x|zip|tar|rar)$/i;
          if (
            event.current.dataset.type === 'attachment' &&
            attachmentPatt.test(event.current.value) === false
          ) {
            fileTypeError = false;
          }

          //setState to show video and image preview
          if (
            event.current.files.length > 0 &&
            event.current.dataset.type === 'image'
          ) {
            this.setState({
              showVideo: true,
            });
          } else if (
            event.current.files.length > 0 &&
            event.current.dataset.type === 'video'
          ) {
            this.setState({
              showImage: true,
            });
          }

          // IF File format is ok then work it out
          if (fileTypeError) {
            selectedFiles.push({
              id: counter,
              type: this.setFileType(event.current.files[i]),
              src: URL.createObjectURL(event.current.files[i]),
              name: event.current.files[i].name,
              file: event.current.files[i],
            });
          } else {
            this.props.onAlert('Invalid file format is trying to upload.');
          }

          this.setState({
            counter: counter,
          });
          i++;
        }
      }

      this.setState({
        selectedFiles: selectedFiles,
        showCarousel: true,
      });
      event.current.value = '';
    } catch (error) {
      console.log(error);
    }
  }

  /* Need to create Post then again list again post list with default setting */
  async createPost() {
    try {
      this.props.onLoading(true);

      let pollClone = Object.assign({}, this.state.poll);
      let options = this.state.poll.options.map((item) => {
        return {
          name: item.name,
          order: item.order.toString(),
          url: item.url,
        };
      });

      let poll = this.state.showPollForm > 0 ? '1' : '0';
      let pollQuestion = pollClone.pollQuestion;
      let pollType = pollClone.type;

      let data = {
        title: this.state.title,
        description: this.state.description.value,
        /* images: this.imageFile.current.files,
        videos: this.videoFile.current.files,
        attachments: this.attachmentFile.current.files,*/
        files: this.state.selectedFiles,
        publishNow: 1,
        options: options,
        poll: poll,
        pollQuestion: pollQuestion,
        type: pollType,
        publishLaterDate: this.state.publishLaterDate,
        startsAt: this.state.poll.startsAt ? this.state.poll.startsAt : null,
        closesAt: this.state.poll.closesAt ? this.state.poll.closesAt : null,
      };

      let dataValidation = true;
      // Compare Poll Start and End Date
      console.log(data.startsAt);
      console.log(data.closesAt);
      console.log(data);
      if ((!data.startsAt || !data.closesAt) && poll !== '0') {
        dataValidation = false;
        this.props.onLoading(false);
        this.setState({
          showAlert: true,
          alertMsg: 'Both Poll Start and End date are mandatory',
        });
        return false;
      }
      if (new Date(data.startsAt) > new Date(data.closesAt) && poll !== '0') {
        console.log(poll);
        dataValidation = false;
        this.props.onLoading(false);
        this.setState({
          showAlert: true,
          alertMsg: 'Poll Started Date must not be greater than Poll End date',
        });
        return false;
      }
      if (data.options.length < 2 && this.state.showPollForm) {
        dataValidation = false;
        this.props.onLoading(false);
        this.setState({
          showAlert: true,
          alertMsg: 'Options need to be minimum 2',
        });
      }
      if (this.state.showPollForm && data.startsAt === null) {
        this.props.onLoading(false);
        this.setState({
          showAlert: true,
          alertMsg: 'Poll Schedule is required',
        });
      }
      if (data.pollQuestion.trim().length === 0 && this.state.showPollForm) {
        dataValidation = false;
        this.props.onLoading(false);
        this.setState({
          showAlert: true,
          alertMsg: 'Question Poll is required',
        });
      }

      if (data.title.trim().length === 0) {
        dataValidation = false;
        this.props.onLoading(false);
        this.setState({
          showAlert: true,
          alertMsg: 'Post title  is required',
        });
      }
      /* News Description  */
      if (data.description.length === 0) {
        dataValidation = false;
        this.props.onLoading(false);
        this.setState({
          showAlert: true,
          alertMsg: 'Post Description is required',
        });
      }

      if (data.publishLaterDate && data.startsAt) {
        if (data.publishLaterDate > data.startsAt) {
          console.log(data.publishLaterDate);
          console.log(data.startsAt);
          this.setState({
            showAlert: true,
            alertMsg: 'Poll will not be published until the post is published ',
          });
        }
      }
      /* Share Width Option interated  */

      if (this.state.sharewith) {
        if (this.state.sharewith.value === 'onlyadmin') {
          data.isSharedWithAdmin = 1;
        } else if (this.state.sharewith.value === 'onlylearners') {
          data.isSharedWithLearner = 1;
        } else if (this.state.sharewith.value === 'private') {
          data.onlySharedWith = 1;
        }
      }

      if (dataValidation) {
        await API.createPost(data)
          .then((response) => {
            if (response.status && response.status === 200) {
              window.location.reload();
              /*
              let post = Global.createNewsFeedObj(response.data.data);

              this.props.onAddPost(post);
              this.props.onLoading(false);

              this.setState({
                showTitle: false,
                showContSection: false,
                showCarousel: true,
                showSchedulePanel: false,
                showPollForm: false,
                title: '',
                selectedFiles: [],
                showCreatePollBtn: false,
                publishLaterDate: null,
                poll: {
                  options: [],
                  type: 'single',
                  startsAt: null,
                  pollQuestion: '',
                },
                description: {
                  value: '',
                },
              });
              */
            } else {
              this.props.onLoading(false);
              this.props.onAlert(response.data.data);
            }
          })
          .catch((error) => {
            console.log('Error Response', error);
            this.props.onAlert(error.data.data);
            console.log(error);
          });
      }
    } catch (error) {
      console.log(error);
    }
  }

  createSchedulePost(val, trigger) {
    try {
      console.log(val);
      this.setState((prevState) => {
        return {
          showSchedulePanel: false,
          publishLaterDate: val,
        };
      });
    } catch (error) {
      console.log(error);
    }
  }
  findType(item) {
    try {
      return item.imageThumbnailUrl && item.imageThumbnailUrl.length > 0
        ? 'image'
        : item.videoThumbnailUrl && item.videoThumbnailUrl.length > 0
        ? 'video'
        : item.attachmentUrl && item.attachmentUrl.length > 0
        ? 'attachment'
        : false;
    } catch (error) {
      console.log(error);
    }
  }

  filterMedias(medias) {
    try {
      return medias && medias.length > 0
        ? medias.map((item) => {
            return {
              id: item.id || item['_id'],
              type: this.findType(item),
              src: item.imageThumbnailUrl,
              name: item.name,
              file: null,
            };
          })
        : [];
    } catch (error) {
      console.log(error);
    }
  }
  componentDidMount() {
    try {
      // User Profile

      API.userInfo()
        .then((response) => {
          let userInfo = {
            name: response.name ? response.name : 'Olive',
            profilePic: response.profilePic
              ? response.profilePic
              : '/img/logo.png',
            orgIcon: response.orgIcon ? response.orgIcon : '/img/logo.png',
            orgName: response.orgName ? response.orgName : 'Olive',
          };
          this.setState({
            userInfo: userInfo,
          });
        })
        .catch((error) => console.log(error));

      let id = this.props.id && this.props.id.length > 0 ? this.props.id : null;
      let newsFeedID =
        this.props.newsFeedID && this.props.newsFeedID.length > 0
          ? this.props.newsFeedID
          : null;

      let title = this.props.title ? this.props.title : '';
      let showTitle =
        this.props.title && this.props.title.length > 0 ? true : false;

      let description =
        this.props.description && this.props.description.length > 0
          ? this.props.description
          : '';

      let showContSection =
        this.props.description && this.props.description.length > 0
          ? true
          : false;

      let showCarousel =
        this.props.medias && this.props.medias.length > 0 ? true : false;
      let mode =
        this.props.mode && this.props.mode.length > 0
          ? this.props.mode
          : this.state.mode;
      let poll = Object.assign({}, this.state.poll);

      if (this.props.poll) {
        poll.type = this.props.poll.type;
        poll.startsAt = this.props.poll.startsAt;
        poll.closesAt = this.props.poll.closesAt;
        poll.pollQuestion = this.props.poll.pollQuestion;
        poll.id = this.props.poll._id;
        poll.options =
          this.props.poll.options.length > 0
            ? this.props.poll.options.map((item) => {
                return {
                  id: item._id,
                  name: item.optionName,
                  order: item.optionOrder,
                  sharewith: 'everyone',
                  url: item.optionThumbnail,
                };
              })
            : '';
      }
      let isSchedule = this.props.isSchedule ? this.props.isSchedule : false;
      let showCreatePollBtn = () => {
        let show = false;
        if (this.props.mode === 'edit') {
          show = poll.options.length > 0 ? false : true;
        } else {
          show = false;
        }
        return show;
      };
      let publishAt = this.props.publishAt
        ? new Date(this.props.publishAt)
        : null;

      var sharewithValue;
      var sharewithoptions = [...this.state.sharewithoptions];
      sharewithoptions[3]['to'] = this.props.creatorId;

      if (this.props.isSharedWithAll) {
        sharewithValue = sharewithoptions[0];
      } else if (this.props.isSharedWithAdmin) {
        sharewithValue = sharewithoptions[1];
      } else if (this.props.isSharedWithLearner) {
        sharewithValue = sharewithoptions[2];
      } else if (this.props.onlySharedWith) {
        sharewithValue = sharewithoptions[3];
      }

      let allMedia = [];
      if (this.props.medias && this.props.medias.length > 0) {
        this.props.medias.forEach((item) => allMedia.push(item));
      }
      if (this.props.attachments && this.props.attachments.length > 0) {
        this.props.attachments.forEach((item) => allMedia.push(item));
      }

      //  selectedFiles: allMedia.length > 0 ? this.filterMedias(allMedia) : [],
      //  selectedFiles: this.filterMedias(this.props.medias),
      this.setState({
        title: title,
        showTitle: showTitle,
        creatorId: this.props.creatorId,
        description: { value: description },
        showContSection: showContSection,
        showCarousel: showCarousel,
        selectedFiles: this.filterMedias(allMedia),
        mode: mode,
        id: id,
        isSchedule: isSchedule,
        newsFeedID: newsFeedID,
        poll: poll,
        publishLaterDate: publishAt,

        showPollForm: mode === 'edit' ? true : false,
        showCreatePollBtn: showCreatePollBtn(),
        sharewithoptions: sharewithoptions,
        sharewith: sharewithValue,
      });

      if (this.props.mode === 'edit' && this.props.medias) {
        let alreadyImages = this.props.medias?.some(
          (media) => media?.imageThumbnailUrl,
        );
        let alreadyVideos = this.props.medias?.some(
          (media) => media?.videoThumbnailUrl,
        );

        if (alreadyImages) {
          this.setState({
            showImage: false,
            showVideo: true,
          });
        }
        if (alreadyVideos) {
          this.setState({
            showImage: true,
            showVideo: false,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  updatePost() {
    try {
      let obj = {};
      obj.newsFeedID = this.state.newsFeedID;
      obj.title = this.state.title;
      obj.description = this.state.description.value;
      obj.files = this.state.selectedFiles;
      obj.deletedMedia = this.state.deletedItems;
      obj.newsFeedClientId = this.state.id;
      obj.publishLaterDate = this.state.publishLaterDate;

      if (this.state.publishNow) {
        obj.publishNow = '1';
      }
      /* Share With 
      everyone,onlyadmin,onlylearners,private
      */

      if (this.state.sharewith) {
        if (this.state.sharewith.value === 'onlyadmin') {
          obj.isSharedWithAdmin = 1;
        } else if (this.state.sharewith.value === 'onlylearners') {
          obj.isSharedWithLearner = 1;
        } else if (this.state.sharewith.value === 'private') {
          obj.onlySharedWith = 1;
        } else {
          obj.isSharedWithAll = '1';
        }
      }

      this.props.onLoading(true);
      API.updatePost(obj)
        .catch((error) => {
          this.props.onLoading(false);
          console.log(error);
        })
        .then((response) => {
          if (response && response.data && response.data.data) {
            let data = response.data.data;
            if (this.state.publishNow === 1) {
              window.location.reload();
              return false;
            }

            this.props.onPublishLaterDate(this.state.publishLaterDate);

            data.isSharedWithAll =
              this.state.sharewith.value === 'everyone' ? true : null;
            data.isSharedWithAdmin =
              this.state.sharewith.value === 'onlyadmin' ? true : null;
            data.isSharedWithLearner =
              this.state.sharewith.value === 'onlylearners' ? true : null;
            data.onlySharedWith =
              this.state.sharewith.value === 'private' ? this.state.id : null;

            this.props.onUpdated(data);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }
  async deleteItem(item) {
    try {
      let selectedFiles = [...this.state.selectedFiles];
      let deletedItems = [...this.state.deletedItems];

      let ele = selectedFiles.find((obj) => obj.id === item.id);
      let index = selectedFiles.indexOf(ele);

      selectedFiles.splice(index, 1);
      deletedItems.push(item.id);

      await this.setState({
        selectedFiles: selectedFiles,
        deletedItems: deletedItems,
      });
    } catch (error) {
      console.log(error);
    }
  }
  setPollFormStatus() {
    try {
      this.setState((prevState) => {
        return {
          showPollForm: prevState.showPollForm ? false : true,
          showCreatePollBtn: prevState.showCreatePollBtn ? false : true,
        };
      });
    } catch (error) {
      console.log(error);
    }
  }
  removeOption(obj) {
    try {
      let poll = Object.assign({}, this.state.poll);
      let options = poll.options;
      let index = options.findIndex((item) => item.id === obj.id);
      options.splice(index, 1);
      this.setState({
        poll: poll,
      });
    } catch (error) {
      console.log(error);
    }
  }
  addOptions(tempOption) {
    try {
      let poll = Object.assign({}, this.state.poll);
      let options = poll.options;

      options.push(tempOption);

      this.setState({
        poll: poll,
      });
    } catch (error) {
      console.log(error);
    }
  }
  editOption(item) {
    let poll = Object.assign({}, this.state.poll);
    let options = poll.options;
    let obj = options.find((obj) => obj.id === item.id);
    let index = options.indexOf(obj);
    options[index] = item;
    this.setState({
      poll: poll,
    });
  }
  changePollType(val) {
    try {
      let poll = Object.assign({}, this.state.poll);
      poll.type = val;
      this.setState({
        poll: poll,
      });
    } catch (error) {
      console.log(error);
    }
  }
  changePollTitle(val) {
    try {
      let poll = Object.assign({}, this.state.poll);
      poll.pollQuestion = val;
      this.setState({
        poll: poll,
      });
    } catch (error) {
      console.log(error);
    }
  }
  closePoll() {
    try {
      this.setState({
        showPollForm: false,
        showCreatePollBtn: true,
        poll: {
          options: [],
          type: 'single',
          startsAt: null,
          pollQuestion: '',
          showCreatePollBtn: true,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }
  changeSchedulePoll(val, trigger) {
    try {
      console.log(val);
      let poll = Object.assign({}, this.state.poll);
      if (trigger === 'close') {
        poll.closesAt = val;
      } else {
        poll.startsAt = val;
      }

      this.setState({
        poll: poll,
        showSchedulePanel: false,
      });
    } catch (error) {
      console.log(error);
    }
  }
  hideAlert() {
    try {
      this.setState({
        showAlert: false,
        alertMsg: '',
      });
    } catch (error) {
      console.log(error);
    }
  }
  isPollStarted() {
    try {
      if (
        this.state.poll.startsAt &&
        new Date() > new Date(this.state.poll.startsAt)
      ) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  }
  hidePollForm() {
    try {
      console.log('hidePollForm');
      this.setState({
        showPollForm: false,
      });
    } catch (error) {
      console.log(error);
    }
  }
  removePoll() {
    try {
      let payload = { id: this.state.poll.id };
      API.poll
        .remove(payload)
        .catch((error) => console.log(error))
        .then((response) => {
          console.log(response);

          this.setState({
            showPollForm: false,
            alertMsg: 'Poll has been sucessfully removed',
            showAlert: true,
          });
        });
    } catch (error) {
      console.log(error);
    }
  }

  setConfirmBoxStatus() {
    try {
      this.setState({
        confirmBoxStatus: true,
      });
    } catch (error) {
      console.log(error);
    }
  }
  showCreateBtn() {
    try {
      if (this.state.mode === 'edit') {
        return false;
      } else {
        return this.state.showCreatePollBtn ? true : false;
      }
    } catch (error) {
      console.log(error);
    }
  }
  hideSchedulePanel() {
    this.setState({
      showSchedulePanel: false,
    });
  }
  convertTwelveHours(val) {
    let hrs = parseInt(val);
    if (hrs === 0 || hrs === 12) {
      hrs = 12;
    } else if (hrs > 12) {
      hrs = hrs - 12;
    }
    return hrs;
  }
  setAMPM(val) {
    let ampm = null;
    if (val === 0) {
      ampm = 'PM';
    } else if (val === 12) {
      ampm = 'AM';
    } else if (val > 0 && val < 12) {
      ampm = 'AM';
    } else if (val > 12) {
      ampm = 'PM';
    }
    return ampm;
  }
  showLocalDate(mdate) {
    try {
      let hours = mdate.getHours();

      let minutes = null;

      let da = mdate;
      let localMinute = da.getMinutes();
      let utcMinute = da.getUTCMinutes();
      minutes = Math.abs((localMinute - utcMinute + 30) % 60);

      let val = mdate.toString();

      var splitDate = val.split(' ');
      return (
        <span className="datedisplay">
          <span>Your users will start seeing the news feed from </span>
          <span>{splitDate[0]} ,</span> <span> {splitDate[1]} </span>{' '}
          <span> {splitDate[2]} </span>
          <span> </span>
          <span> at</span> {this.convertTwelveHours(hours)} : {minutes}
          {
            <>
              <span> {this.setAMPM(hours)}</span>
            </>
          }
        </span>
      );
    } catch (error) {
      console.log(error);
    }
  }
  setRole(item) {
    try {
      this.setState({
        sharewith: item,
      });
    } catch (error) {
      console.log(error);
    }
  }
  render() {
    const {
      sharewith,
      isSchedule,
      sharewithoptions,
      logo,
      showTitle,
      showPollForm,
      poll,
      mode,
      title,
      showContSection,
      showAlert,
      alertMsg,
      showCarousel,
      selectedFiles,
      publishLaterDate,
      publishAt,
      showSchedulePanel,
      userInfo,
      showImage,
      showVideo,
    } = this.state;

    return (
      <>
        {showAlert ? (
          <AlertBox onOK={() => this.hideAlert()} msg={alertMsg} />
        ) : (
          ''
        )}

        <div className="postCreator">
          <section className="postForm">
            <header>
              {userInfo ? (
                <div className="img">
                  <img src={userInfo.orgIcon} alt="Olive" />
                </div>
              ) : (
                ''
              )}

              <div className="rightSide">
                {!showTitle ? (
                  <>
                    <h4 onClick={() => this.showTitle()}>
                      What's on your mind
                    </h4>
                  </>
                ) : (
                  <>
                    <h3 className="name">
                      {userInfo ? userInfo.orgName : 'Olive'}
                    </h3>
                    <div className="sharing">
                      <strong>Sharing with:</strong>
                      <RoleList
                        options={sharewithoptions}
                        onSelect={(item) => this.setRole(item)}
                        value={sharewith}
                      />
                    </div>
                  </>
                )}
              </div>
            </header>

            {showTitle ? (
              <div className="cont">
                {sharewith && sharewith.value === 'onlylearners' ? (
                  <div className="warnimgMsg">
                    {' '}
                    <img src="/img/warning.svg" width="15" height="15" />
                    When a post is published, admins will not be able to see,
                    edit or delete it
                  </div>
                ) : (
                  ''
                )}
                <input
                  type="text"
                  onChange={this.setPostTitle}
                  value={title}
                  onClick={() => {
                    this.showContent();
                  }}
                  className="postTitle"
                  placeholder="Type here to title your post"
                />
                {showContSection ? (
                  <>
                    <CKEditor
                      editor={ClassicEditor}
                      data={this.getContent()}
                      config={this.state.editorConfiguration}
                      onInit={(description) => {
                        // You can store the "editor" and use when it is needed.
                        // console.log( 'Editor is ready to use!', description );
                      }}
                      onChange={(event, description) => {
                        const data = description.getData();
                        // console.log( { event, description, data } );
                        this.setState({
                          description: { value: data },
                        });
                      }}
                    />

                    {showCarousel && selectedFiles.length > 0 ? (
                      <PostCarousel
                        items={selectedFiles}
                        onDeleteItem={(item) => this.deleteItem(item)}
                        toggleImage={() => this.toggleImage()}
                        toggleVideo={() => this.toggleVideo()}
                        toggleImageVideo={() => this.toggleImageVideo()}
                      />
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  ''
                )}
              </div>
            ) : (
              ''
            )}

            {mode != 'edit' && showPollForm === true ? (
              <Poll
                options={poll.options}
                startsAt={poll.startsAt}
                closesAt={poll.closesAt ? poll.closesAt : null}
                type={poll.type}
                pollQuestion={poll.pollQuestion}
                onAlert={(val) => this.props.onAlert(val)}
                onHidePoll={() => this.hidePollForm()}
                onRemoveOption={(val) => this.removeOption(val)}
                onAddOption={(val) => this.addOptions(val)}
                onEdit={(item) => this.editOption(item)}
                onChangeType={(val) => this.changePollType(val)}
                onLoading={(val) => this.props.onLoading(val)}
                onChangeTitle={(val) => this.changePollTitle(val)}
                onSchedulePoll={(val, trigger) =>
                  this.changeSchedulePoll(val, trigger)
                }
                onClosePoll={() => this.closePoll()}
              />
            ) : mode === 'edit' && showPollForm === true && poll.id != null ? (
              <EditPoll
                id={poll.id}
                options={poll.options}
                startsAt={poll.startsAt ? poll.startsAt : null}
                closesAt={poll.closesAt ? poll.closesAt : null}
                type={poll.type}
                pollQuestion={poll.pollQuestion}
                onAlert={(val) => this.props.onAlert(val)}
                onEditedPoll={(val) => this.props.onEditedPoll(val)}
                onLoading={(val) => this.props.onLoading(val)}
                onClosePoll={() => this.hidePollForm()}
                onRemovePoll={() => this.removePoll()}
                isStarted={this.isPollStarted()}
                onSchedulePoll={(val, trigger) =>
                  this.changeSchedulePoll(val, trigger)
                }
              />
            ) : (
              ''
            )}

            {showContSection ? (
              <AlterPostFooter
                mode={mode}
                isSchedule={isSchedule}
                title={title}
                hasPoll={poll.options.length > 0}
                publishLaterDate={publishLaterDate}
                onAddImageFile={() => this.addImageFile()}
                onAddToCarousel={(e) => this.addToCarousel(e)}
                onAddVideoFile={() => this.addVideoFile()}
                onAddAttachmentFile={() => this.addAttachmentFile()}
                onSetPollFormStatus={(val) => this.setPollFormStatus(val)}
                onShowSchedulePanel={() => this.showSchedulePanel()}
                onCreatePost={() => this.createPost()}
                onUpdatePost={() => this.updatePost()}
                onChangeMode={(val) => this.closeCreatePost()}
                showImage={showImage}
                showVideo={showVideo}
              />
            ) : (
              ''
            )}
          </section>
          {showSchedulePanel ? (
            <SchedulePost
              name={mode + 'post'}
              onStatus={() => this.hideSchedulePanel()}
              onScheduled={(val, trigger) =>
                this.createSchedulePost(val, trigger)
              }
              onAlert={(val) => this.props.onAlert(val)}
              onRemove={() => this.removeSchedule()}
              value={
                this.state.publishLaterDate
                  ? this.state.publishLaterDate
                  : publishAt
              }
            />
          ) : (
            ''
          )}
        </div>
      </>
    );
  }
}

export default React.memo(CreatePost);
